import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ja from 'vuetify/lib/locale/ja';
import en from 'vuetify/lib/locale/en';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ja, en },
    current: window.localStorage.getItem('tuttiLangForConsole') || 'en',
  },
})