import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'

Vue.config.productionTip = false

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: window.localStorage.getItem('tuttiLangForConsole'),
  fallbackLocale: 'en',
  messages: {
    ja: require('@/locales/ja.json'),
    en: require('@/locales/en.json')
  }
})

new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
